import { createApp } from "vue";
import App from "./App.vue";
import "@/utils/rem";
import "vant/lib/index.css";
import {
    Button,
    Tabbar,
    TabbarItem,
    Icon,
    Loading,
    Sticky,
    NavBar,
    Checkbox,
    CheckboxGroup,
    Dialog,
} from "vant";
// 引入路由
import router from "./router";
import "./assets/baseCss.css";
// 字体
import "./assets/fonts/font.css";
let app = createApp(App);
app.use(router);
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Icon);
app.use(Loading);
app.use(Sticky);
app.use(NavBar);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Dialog);
app.mount("#app");
