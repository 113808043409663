import { createRouter, createWebHistory } from "vue-router";
// createWebHistory;  历史模式
// createWebHashHistory;  哈希模式
let routes = [
    // {
    //     path: "/",
    //     component: () => import("@/views/index.vue"),
    //     redirect: "/home",
    //     children: [
    //         {
    //             path: "home",
    //             name: "home",
    //             component: () => import("@/views/home/index.vue"),
    //             meta: {
    //                 title: "首页",
    //             },
    //         },
    //         {
    //             path: "equity",
    //             name: "equity",
    //             component: () => import("@/views/equity/index.vue"),
    //             meta: {
    //                 title: "权益",
    //             },
    //         },
    //         {
    //             path: "mine",
    //             name: "mine",
    //             component: () => import("@/views/mine/index.vue"),
    //             meta: {
    //                 title: "我的",
    //             },
    //         },
    //     ],
    // },
    {
        path: "/myOrder",
        name: "myOrder",
        component: () => import("@/views/mine/components/myOrder.vue"),
        meta: {
            title: "我的订单",
        },
    },
    {
        path: "/qysign",
        name: "qysign",
        component: () => import("@/views/ldy/qysign.vue"),
    },
    {
        path: "/qysign2",
        name: "qysign2",
        component: () => import("@/views/ldy/qysign2.vue"),
    },
    {
        path: "/callBack",
        name: "callBack",
        component: () => import("@/views/ldy/callBack.vue"),
    },
    {
        path: "/rules",
        name: "rules",
        component: () => import("@/views/ldy/rules.vue"),
    },
    {
        path: "/ldy1",
        name: "ldy1",
        component: () => import("@/views/ldy/ldy1.vue"),
    },
];

// 3. 创建路由实例并传递 `routes` 配置。
const router = createRouter({
    // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    // 将通用路由和模块路由合并
    routes: routes,
});

// router.afterEach((to) => {
//     document.title = "骊龙权益——" + to.meta.title;
// });

export default router;
